<template>
	<Box
		class="notification"
		:class=" type "
	>
		<div class="header">
			<h3>
				{{ title }}
			</h3>
			<Icon
				class="close"
				name="ion:close-outline"
				@click=" notifications.removeNotification ( id ) "
			/>
		</div>
		<p>
			{{ message }}
		</p>
	</Box>
</template>

<script lang="ts" setup>
const props = defineProps ( {
	id: {
		type: String,
		required: true
	},
	title: {
		type: String,
		required: true
	},
	message: {
		type: String,
		required: true
	},
	createdAt: {
		type: Date,
		required: true
	},
	type: {
		type: String,
		default: 'info'
	}
} )

const notifications = useNotificationsStore ()

if ( props.type !== 'error' ) {
	setTimeout ( () => {
		notifications.removeNotification ( props.id )
	}, 5000 )
}
</script>

<style>
.header {
	display: flex;
	justify-content: space-between;
}

.close {
	cursor: pointer;
}

h3 {
	font-size: 1em;
	margin: 0;
	margin-bottom: 0.5em;
}

p {
	margin: 0;
}

.success h3 {
	color: #4caf50;
}

.error h3 {
	color: #f44336;
}

.info h3 {
	color: #2196f3;
}

.warning h3 {
	color: #ff9800;
}
</style>

