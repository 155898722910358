<template>
	<Header>
		<Anchor to="/">
			<Button>Home</Button>
		</Anchor>
		<Anchor
			v-if="!me"
			to="/log_in"
		>
			<Button>Log in</Button>
		</Anchor>
		<template v-else>
			<Anchor
				v-if="me.isAdmin"
				to="/admin"
			>
				<Button>Admin</Button>
			</Anchor>
			<Anchor to="/settings">
				<Button>Settings</Button>
			</Anchor>
			<Anchor to="/log_out">
				<Button>Log out</Button>
			</Anchor>
		</template>
	</Header>

	<Container>
		<slot />
	</Container>

	<NotificationArea :notifications=" notifications.$state.notifications " />
</template>

<script lang="ts" setup>
const me = useState<User> ( 'me' )

const notifications = useNotificationsStore ()


</script>
