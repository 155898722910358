<template>
	<header>
		<slot />
	</header>
</template>

<script lang="ts" setup>

</script>

<style>
header {
	margin-bottom: 2em;
	text-align: right;
}
</style>

