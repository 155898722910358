<template>
	<div class="container">
		<slot />
	</div>
</template>

<style scoped>
.container {
	box-sizing: border-box;
	margin-right: auto;
	margin-left: auto;
	padding: 0 2em
}

.container.--fluid {
	margin-right: 64px;
	margin-left: 64px;
	max-width: none;
}

@media screen and (min-width: 768px) {
	.container {
		max-width: 768px;
		padding: 0
	}
}
</style>

